import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from './common/Header';
import Footer from './common/Footer';
import Home from './routes/Home';
import Cars from './routes/Cars';
import './App.css';

const App = () => {


  return (
    <BrowserRouter>
    <Header />
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="/cars"
        element={<Cars />}
      />
    </Routes>
    <Footer />
    </BrowserRouter>
  );
}

export default App;

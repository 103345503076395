import { useNavigate } from "react-router-dom";
import { Grid } from 'antd';

import Section from "./Section";
import header from '../pictures/miles_auto_header.jpg';
import logo from '../pictures/miles_auto_logo_only.png';

const { useBreakpoint } = Grid;

const Header = () => {

    const breakpoint = useBreakpoint();
    const navigate = useNavigate();

    const screens = Object.entries(breakpoint).filter(entry => !!entry[1]).map(entry => entry[0]);

    return (
      <>
      <Section responsive style={{height: 80, backgroundImage: `url(${header})`}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <img 
            alt="Miles Auto Sales Logo"
            style={{margin: '10px 0px', maxHeight: 60, cursor: 'pointer'}} 
            src={logo}
            onClick={() => navigate('/')}
          />
          {screens.includes('md') ? (
            <div style={{width: 450}}>
              <p style={{color: 'white', fontSize: 24, fontFamily: 'Passion One', display: 'inline-block'}}>(912) 632-6520</p>
              <p style={{color: 'white', fontSize: 24, fontFamily: 'Passion One', display: 'inline-block', margin: 24}}>&#183;</p>
              <p style={{color: 'white', fontSize: 24, fontFamily: 'Passion One', display: 'inline-block'}}> 202 S Pierce St Alma GA 31510</p>
            </div>
            ) : null
          }
        </div>
      </Section>
          {!screens.includes('md') ? (
              <Section responsive style={{backgroundColor: 'black'}}>
                <p style={{color: 'white', fontSize: 20, fontFamily: 'Passion One', display: 'inline-block', margin: '10px 0px'}}>(912) 632-6520</p>
                {screens.includes('sm') ? (<p style={{color: 'white', fontSize: 20, fontFamily: 'Passion One', display: 'inline-block', margin: 6}}>&#183;</p>) : <div style={{width: 8}} />}
                <p style={{color: 'white', fontSize: 20, fontFamily: 'Passion One', display: 'inline-block', margin: '10px 0px'}}> 202 S Pierce St Alma GA 31510</p>
              </Section>
            ) : null
          }
      </>
    );
}

export default Header;
import { useNavigate } from 'react-router-dom';
import { Grid } from 'antd';

import Section from '../common/Section';
import hero from '../pictures/_hero-no-text.jpg';

const { useBreakpoint } = Grid;

const Home = () => {

  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  const getHeroFontSize = () => {
    if (breakpoint.xl) {
      return {fontSize: 96};
    } else if (breakpoint.lg) {
      return {fontSize: 80};
    } else if (breakpoint.md) {
      return {fontSize: 64};
    } else if (breakpoint.sm) {
      return {fontSize: 48};
    } else if (breakpoint.xs) {
      return {fontSize: 32};
    }
  }

  const getButtonSize = () => {
    if (breakpoint.xs) {
      return {height: 60, width: 180, lineHeight: '60px', marginTop: 48};
    } else {
      return {height: 100, width: 300, lineHeight: '100px', marginTop: 150};
    }
  }

  const getButtonTextStyle = () => {
    if (breakpoint.xs) {
      return {marginLeft: 24, fontSize: 24};
    } else {
      return {marginLeft: 40, fontSize: 40};
    }
  }

  const getHeroSectionStyle = () => {
    if (breakpoint.xs) {
      return {marginTop: 32, height: 360}
    } else {
      return {marginTop: 64, height: 616}
    }
  }

  const getWrapperSectionStyle = () => {
    if (breakpoint.xs) {
      return {height: 512}
    } else {
      return {height: 1024}
    }
  }

  return (
      <Section style={{backgroundImage: `url(${hero})`, ...getWrapperSectionStyle()}}>
        <Section responsive style={getHeroSectionStyle()}>
        <div>
          <h1 style={{lineHeight: '125%', color: 'white', fontFamily: 'Archivo', fontWeight: '900', ...getHeroFontSize()}}>
            Your preferred <br />automotive dealership <br />serving Alma since 1992
          </h1>
        </div>
        <div 
          id="action"
          style={{cursor: 'pointer', backgroundColor: '#b11a0d', borderRadius: 30, ...getButtonSize()}}
          onClick={() => navigate('/cars')}
        >
          <p style={{color: 'white', fontFamily: 'Passion One', ...getButtonTextStyle()}}>Find Your Ride</p>
        </div>
        </Section>
      </Section>
  );
}

export default Home;

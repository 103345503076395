import { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Grid } from 'antd';

import Section from '../common/Section';
import background from '../pictures/_hero-no-text.jpg';

const { Meta } = Card;
const { useBreakpoint } = Grid;

const Car = ({car}) => {
  return (
    <div style={{padding: 8, height: 250}}>
      <Card
        hoverable
        style={{
          width: 240,
          height: '100%'
        }}
        cover={<img alt="example" src={car.mediaLink} style={{height: 142}}/>}
      >
        <Meta title={`${car.year} ${car.make} ${car.model}`} description={car.price} />
      </Card>
    </div>
  )
}

const Cars = () => {

  const BUCKET_URL = "https://storage.googleapis.com/storage/v1/b/miles-auto-sales/o";

  const [pictures, setPictures] = useState([]);
  const breakpoint = useBreakpoint();

  const getImages = (bucketResults) => {
    const re = new RegExp("(jpg|jpeg|png)");
    return bucketResults.filter(result => re.test(result.name));
  }

  const makeCarsWithoutImage = async (items) => {
    const carInfoObject = items.find(item => item.name === 'car-info.csv');
    const carInfoCsv = await axios.get(carInfoObject.mediaLink);
    const carInfoRows = carInfoCsv.data.split('\n').slice(1);
    const cars = [];
    for (let i = 0; i < carInfoRows.length; i++) {
      let rowData = carInfoRows[i].split(',').map(row => row.trim());
      if (rowData.length === 6) {
        let carInfo = {
          id: rowData[0],
          year: rowData[1], 
          make: rowData[2], 
          model: rowData[3], 
          description: rowData[4], 
          price: `$${Number(rowData[5]).toLocaleString()}`
        }
        cars.push(carInfo);
      }
    } 
    return cars;
  }

  const addImageToCars = (carsWithoutImage, images) => {
    const carsWithImage = [];
    for (let i = 0; i < carsWithoutImage.length; i++) {
      const image = images.find(image => image.name.split('.')[0] === carsWithoutImage[i].id)
      const carWithImage = {...carsWithoutImage[i], mediaLink: image.mediaLink};
      carsWithImage.push(carWithImage);
    }
    return carsWithImage;
  }

  const populateCars = async (items) => {
    const carsWithoutImages = await makeCarsWithoutImage(items);
    const images = getImages(items);
    const carsWithImage = addImageToCars(carsWithoutImages, images);
    setPictures(carsWithImage);
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(BUCKET_URL)
      .then(res => populateCars(res.data.items));
  }, []);

  return (
      <Section responsive style={{backgroundImage: `url(${background})`, backgroundColor: 'black', backgroundRepeat: 'no-repeat'}}>
        <div style={{minHeight: 1026, backgroundColor: 'rgba(0, 0, 0, .8)', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', padding: (breakpoint.xs ? 8 : 48)}}>
          {pictures.map((picture) => <Car car={picture} key={picture.id}/>)}
        </div>
        <div style={{backgroundColor: 'black'}} />
      </Section>
  );
}

export default Cars;